import React from 'react';
import './AppHeader.css';
import { AppNav } from '../app-nav/AppNav';

export const AppHeader = ({ title, description, links, picture }) => {
  return (<header className="AppHeader">
    <AppNav />
    <div className="AppPresentation">
      <div>
        <span className="AppPresentation__Title">{title ?? ""}</span>
        <p className="AppPresentation__Description">
          {description ?? ""}
        </p>
        <div className="AppPresentation__AppLinks">
          {links && <links />}
        </div>
      </div>

      <div>
        {picture}
      </div>
    </div>
  </header>);
}

