import './Paciente.css';
import { AppHeader } from './components/app-header/AppHeader';
import { CNSColors } from './components/CNSColors';
import FooterBlock from './components/footer-block/FooterBlock';

import AppEstabelecimentosImage from './images/AppEstabelecimentosImage.png';
import AppMedicacoesImage from './images/AppMedicacoesImage.png';
import AppPefilMedicoImage from './images/AppPerfilMedicoImage.png';

import AppHomeImage from './components/images/AppHomeImage.png';
import GooglePlayButton from './components/images/GooglePlayButton.svg';
import AppleStoreButton from './components/images/AppStoreButton.svg';

function Paciente() {
  return (
    <div className="Paciente">
      <AppHeader
        title="Retome o controle da sua saúde"
        description={<>
          Com o CNS Paciente você tem seu <span style={{ color: CNSColors.yellow }}>prontuário médico</span> em seu bolso. 
          Além de encontrar especialistas e hospitais, você poderá <span style={{ color: CNSColors.green }}>agendar consultas</span> e exames de 
          forma prática, organizar prescrições e <span style={{ color: CNSColors.yellow }}>alertas de medicações</span> e 
          realizar <span style={{ color: CNSColors.green }}>consultas online</span>.
        </>}
        links={<>
          <img src={GooglePlayButton} height={52} alt="Botao google play" />
          <img src={AppleStoreButton} height={52} alt="Botao Apple Store" />
        </>}
        picture={<img src={AppHomeImage} height={450} alt="Tela inicial do aplicativo CNS Paciente" />}
      />

      <section className="Feature">
        <div>
          <p className="Feature__Title">
            Encontre os <span style={{ color: CNSColors.brand }}>médicos</span> próximos a você
          </p>
          <p className="Feature__Description">
            Com o CNS Paciente você pode pesquisar por médicos, clínicas e hospitais na sua região, facilitando a sua escolha de qual especialista deseja se consultar. Além de poder encontrar telefone de contato e endereço sem burocracia.
          </p>
        </div>
        <div>
          <img src={AppEstabelecimentosImage} height={550} alt="Tela estabelecimentos do aplicativo CNS Brasil" />
        </div>
      </section>
      
      <section className="Feature">
        <div>
          <img src={AppPefilMedicoImage} height={550} alt="Tela perfil médico do aplicativo CNS Brasil" />
        </div>
        <div>
          <p className="Feature__Title">
            Agende você mesmo suas <span style={{ color: CNSColors.brand }}>consultas</span> de forma rápida e prática
          </p>
          <p className="Feature__Description">
            Com o CNS Paciente você pode agendar suas consultas no horário que mais lhe agrada, sem precisar esperar horas no telefone, evitando gastos com ligações e deslocamento.
          </p>
        </div>
      </section>

      <section className="Feature">
        <div>
          <p className="Feature__Title">
            Não perca mais os horários de tomar suas <span style={{ color: CNSColors.brand }}>medicações</span>
          </p>
          <p className="Feature__Description">
            O aplicativo CNS Paciente ajuda você a controlar suas medicações e receitas, físicas ou digitais. Crie alertas para seus medicamentos e acompanhe o progresso do seu tratamento.
          </p>
        </div>
        <div>
          <img src={AppMedicacoesImage} height={550} alt="Tela perfil medicações do aplicativo CNS Brasil" />
        </div>
      </section>

      <section className="ContactSection">
        <div className="ContactCard">
          <div className="ContactCard__Title">
            <span>Tem dúvidas ou sugestões? Entre em contato, estamos aqui para ajudar.</span>
          </div>
          <div className="ContactCard__Form">
            <div className="Row">
              <div className="Field">
                <span className="Label">Nome completo:</span>
                <input className="Input" />
              </div>
              <div className="Field">
                <span className="Label">Assunto:</span>
                <input className="Input" />
              </div>
            </div>

            <div className="Row">
              <div className="Field">
                <span className="Label">E-mail:</span>
                <input className="Input" />
              </div>
              <div className="Field">
                <span className="Label">Telefone:</span>
                <input className="Input" />
              </div>
            </div>

            <div className="Row">
              <div className="Field">
                <span className="Label">Dúvida ou sugestão:</span>
                <textarea className="Input" style={{ height: "115px" }} />
              </div>
            </div>
            
            <div className="Row" style={{ justifyContent: "end" }}>
              <button className="Button">Enviar mensagem</button>
            </div>
          </div>
        </div>
      </section>
      
      <FooterBlock />
    </div>
  );
}

export default Paciente;
