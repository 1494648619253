import React from "react";
import './AppNav.css';

import CNSLogo from './CNSLogo.svg';
import { TabList } from "../tab-list/TabList";
import { useLocation } from "react-router";

export const AppNav = () => {
  const location = useLocation();

  const tabItems = [
    {
      label: "Início", 
      url: "/"
    },
    {
      label: "Sou paciente",
      url: "/paciente"
    },
    {
      label: "Sou médico",
      url: "/medico"
    },
    {
      label: "Contato",
      url: "/contato"
    }
  ];

  return (<div className="AppNav">
    <div className="AppLogo">
      <img src={CNSLogo} height={32} alt="Logo CNS Brasil" />
      <span>CNS Brasil</span>
    </div>

    <TabList activeTab={location.pathname} items={tabItems} />
  </div>);
}
