import { useNavigate } from 'react-router';
import './TabList.css';

export const TabList = ({ items, activeTab }) => {
  const nav = useNavigate();

  return (<ul className="TabList">
    {items && items.map(item => <li className="TabItem">
      <span onClick={() => nav(item.url)}>{item.label}</span>
      {item.url === activeTab && <div className="ActiveMarker" />}
    </li>)}
  </ul>);
}
