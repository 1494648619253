import React, { useState } from "react";

import MaleDoctorAvatar from "./images/MaleDoctorAvatar.svg";
import CNSBrasilQRCode from "./images/CNSBrasilQRCode.svg";

import { CheckmarkSquare24Filled } from "@fluentui/react-icons";
import { Switch } from "@fluentui/react-components";
import ScreenHelper from "../helpers/ScreenHelper";
import { CNSColors } from "./CNSColors";

const PlansBlock = ({ id }) => {
  const [cnsCloudSelected, setCnsCloudSelected] = useState(true);

  return (
    <div style={styles.PlansBlock} id={id}>
      <div style={styles.Feature}>
        <div style={styles.Text}>
          <span style={styles.Text.Title}>Preços simples e transparente</span>
          <span style={styles.Text.Description}>A CNS Brasil presa pela transparência, por isso temos uma politica de preços simples e sem surpresas.</span>
        </div>

        <div style={styles.SwitchPlanTypeSection}>
          <span style={styles.SwitchPlanTypeSection.Text}>Servidor Dedicado</span>
          <Switch checked={cnsCloudSelected} onChange={(evt) => setCnsCloudSelected(evt.target.checked)} />
          <span style={styles.SwitchPlanTypeSection.Text}>CNS Pass</span>
        </div>

        {cnsCloudSelected ? <CNSPassCardList />: <DedicatedServerCardList />}
      </div>
    </div>
  );
};

const CNSPassCardList = () => {
  const _small = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",

    Card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "stretch",

      CardPass: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "22px",
        padding: "24px 48px",
        backgroundColor: CNSColors.brand,
        borderBottom: "dashed 1px #ffffff",
        borderRadius: "24px",
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "0px",

        Title: {
          display: "flex",
          flexDirection: "column",
          gap: "12px",

          Picture: {
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            justifyContent: "center",
            alignItems: "center",
            height: "155px"
          },

          Details: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            Name: {
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "35px",
              color: "#ffffff"
            },

            Position: {
              fontSize: "16px",
              lineHeight: "30px",
              textAlign: "center",
              color: "#ECECEC"
            }
          }
        },

        PlanDetails: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }
      },

      Plan: {
        display: "flex",
        flexDirection: "column",
        gap: "22px",
        padding: "24px",
        backgroundColor: "#ffffff",
        border: "inset 1px rgba(0,0,0,0.15",
        borderRadius: "24px",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",

        Title: {
          width: "300px",
          fontSize: "16px",
          lineHeight: "30px",
          textAlign: "center",
          color: CNSColors.brand
        },

        Details: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,

          PriceSection: {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",

            Price: {
              fontWeight: "bold",
              fontSize: "40px",
              lineHeight: "66px",
              textAlign: "center",
              color: CNSColors.brand
            },

            Period: {
              fontSize: "16px",
              lineHeight: "22px",
              color: CNSColors.brand
            }
          }
        }
      }
    }
  };

  const _ = ScreenHelper().isSmall() ? { ..._small } : {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",

    Card: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "stretch",

      CardPass: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "22px",
        padding: "24px 48px",
        backgroundColor: CNSColors.brand,
        borderRight: "dashed 1px #ffffff",
        borderRadius: "24px",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        boxShadow: "0px 2px 6px rgba( 20, 20, 43, 0.25)",

        Title: {
          display: "flex",
          flexDirection: "column",
          gap: "12px",

          Picture: {
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            justifyContent: "center",
            alignItems: "center",
            height: "155px"
          },

          Details: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            Name: {
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "35px",
              color: "#ffffff"
            },

            Position: {
              fontSize: "16px",
              lineHeight: "30px",
              textAlign: "center",
              color: "#ECECEC"
            }
          }
        },

        PlanDetails: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }
      },

      Plan: {
        display: "flex",
        flexDirection: "column",
        gap: "22px",
        padding: "24px",
        backgroundColor: "#ffffff",
        border: "inset 1px rgba(0,0,0,0.15",
        borderRadius: "24px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        boxShadow: "0px 2px 6px rgba( 20, 20, 43, 0.25)",

        Title: {
          width: "300px",
          fontSize: "16px",
          lineHeight: "30px",
          textAlign: "center",
          color: CNSColors.brand
        },

        Details: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,

          PriceSection: {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",

            Price: {
              fontWeight: "bold",
              fontSize: "40px",
              lineHeight: "66px",
              textAlign: "center",
              color: CNSColors.brand
            },

            Period: {
              fontSize: "16px",
              lineHeight: "22px",
              color: CNSColors.brand
            }
          }
        }
      }
    }
  };

  return (
    <div style={_}>
      <div style={_.Card}>
        <div style={_.Card.CardPass}>
          <div style={_.Card.CardPass.Title}>
            <div style={_.Card.CardPass.Title.Picture}>
              <img src={MaleDoctorAvatar} height="130" alt="Avatar médico" />
            </div>
            <div style={_.Card.CardPass.Title.Details}>
              <span style={_.Card.CardPass.Title.Details.Name}>Passe CNS Brasil</span>
              <span style={_.Card.CardPass.Title.Details.Position}>Oftalmologista</span>
            </div>
            <div style={_.Card.CardPass.PlanDetails}>
              <img src={CNSBrasilQRCode} height="198" width="198" alt="QRCode CNS Brasil" />
            </div>
          </div>
        </div>

        <div style={_.Card.Plan}>
          <span style={_.Card.Plan.Title}>O CNS Pass libera seu acesso a clínica virtual CNS Telemed, aonde poderá atender pacientes em todo o Brasil, nós levamos eles até você. <br/>Também poderá criar e gerenciar sua própria clínica física com a plataforma da CNS Brasil.</span>
          <div style={_.Card.Plan.Details}>
            <div style={_.Card.Plan.Details.PriceSection}>
              <span style={_.Card.Plan.Details.PriceSection.Price}>R$ 49,90</span>
              <span style={_.Card.Plan.Details.PriceSection.Period}> / mês</span>
            </div>
          </div>
          <div style={styles.PlanButton}>
            <span style={styles.PlanButton.Text}>Assinar meu CNS Pass</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const DedicatedServerCardList = () => {
  return (
    <div style={styles.CardList}>

          <div style={styles.CardList.PricingCard}>
            <div style={styles.CardList.PricingCard.Plan}>
              <div style={styles.CardList.PricingCard.Plan.PlanTitle}>
                <div style={styles.CardList.PricingCard.Plan.PlanTitle.PlanName}>
                  <span style={styles.CardList.PricingCard.Plan.PlanTitle.PlanName.Title}>Individual</span>
                </div>
                <div style={styles.CardList.PricingCard.Plan.PlanTitle.PlanDescription}>
                  <span style={styles.CardList.PricingCard.Plan.PlanTitle.PlanDescription.Text}>Perfeito para iniciar seu consultório com baixo custo.</span>
                </div>
              </div>
              <div style={styles.CardList.PricingCard.Plan.PlanDetails}>
                <div style={styles.CardList.PricingCard.Plan.PlanDetails.PriceSection}>
                  <span style={styles.CardList.PricingCard.Plan.PlanDetails.PriceSection.Price}>R$ 249,90</span>
                  <span style={styles.CardList.PricingCard.Plan.PlanDetails.PriceSection.Period}> / mês</span>
                </div>
                <div style={styles.CardList.PricingCard.Plan.PlanDetails.Features}>
                  <span style={styles.CardList.PricingCard.Plan.PlanDetails.Features.Title}>O que vem incluso?</span>
                  <div style={styles.FeatureList}>
                    <div style={styles.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color={CNSColors.brand} />
                      <span style={styles.FeatureList.ListItem.Text}>Toda plataforma CNS Brasil</span>
                    </div>

                    <div style={styles.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color={CNSColors.brand} />
                      <span style={styles.FeatureList.ListItem.Text}>Suporte via App e E-mail</span>
                    </div>

                    <div style={styles.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color={CNSColors.brand} />
                      <span style={styles.FeatureList.ListItem.Text}>Servidor dedicado 2U + 70Gb</span>
                    </div>

                    <div style={styles.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color={CNSColors.brand} />
                      <span style={styles.FeatureList.ListItem.Text}>Recomendado para até 5 usuários</span>
                    </div>
                  </div>
                </div>
              </div>

              <div style={styles.PlanButton}>
                <span style={styles.PlanButton.Text}>Selecionar Plano</span>
              </div>
            </div>
          </div>

          <div style={stylesInverted.PricingCard}>
            <div style={stylesInverted.PricingCard.Plan}>
              <div style={stylesInverted.PricingCard.Plan.PlanTitle}>
                <div style={stylesInverted.PricingCard.Plan.PlanTitle.PlanName}>
                  <span style={stylesInverted.PricingCard.Plan.PlanTitle.PlanName.Title}>Consultório</span>
                </div>
                <div style={stylesInverted.PricingCard.Plan.PlanTitle.PlanDescription}>
                  <span style={stylesInverted.PricingCard.Plan.PlanTitle.PlanDescription.Text}>Indicado para consultórios e clínicas com mais de um médico.</span>
                </div>
              </div>

              <div style={stylesInverted.PricingCard.Plan.PlanDetails}>
                <div style={stylesInverted.PricingCard.Plan.PlanDetails.PriceSection}>
                  <span style={stylesInverted.PricingCard.Plan.PlanDetails.PriceSection.Price}>R$ 499,90</span>
                  <span style={stylesInverted.PricingCard.Plan.PlanDetails.PriceSection.Period}> / mês</span>
                </div>
                <div style={stylesInverted.PricingCard.Plan.PlanDetails.Features}>
                  <span style={stylesInverted.PricingCard.Plan.PlanDetails.Features.Title}>O que vem incluso?</span>
                  <div style={stylesInverted.FeatureList}>
                    <div style={stylesInverted.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color="#ffffff" />
                      <span style={stylesInverted.FeatureList.ListItem.Text}>Toda plataforma CNS Brasil</span>
                    </div>

                    <div style={stylesInverted.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color="#ffffff" />
                      <span style={stylesInverted.FeatureList.ListItem.Text}>Suporte via App e E-mail</span>
                    </div>

                    <div style={stylesInverted.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color="#ffffff" />
                      <span style={stylesInverted.FeatureList.ListItem.Text}>Servidor dedicado 4U + 120Gb</span>
                    </div>

                    <div style={stylesInverted.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color="#ffffff" />
                      <span style={stylesInverted.FeatureList.ListItem.Text}>Recomendado para até 15 usuários</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style={stylesInverted.PlanButton}>
                <span style={stylesInverted.PlanButton.Text}>Selecionar Plano</span>
              </div>
            </div>
          </div>

          <div style={styles.CardList.PricingCard}>
            <div style={styles.CardList.PricingCard.Plan}>
              <div style={styles.CardList.PricingCard.Plan.PlanTitle}>
                <div style={styles.CardList.PricingCard.Plan.PlanTitle.PlanName}>
                  <span style={styles.CardList.PricingCard.Plan.PlanTitle.PlanName.Title}>Multi Clínica</span>
                </div>
                <div style={styles.CardList.PricingCard.Plan.PlanTitle.PlanDescription}>
                  <span style={styles.CardList.PricingCard.Plan.PlanTitle.PlanDescription.Text}>Ideal para clínicas de médio porte ou com mais de uma unidade.</span>
                </div>
              </div>

              <div style={styles.CardList.PricingCard.Plan.PlanDetails}>
                <div style={styles.CardList.PricingCard.Plan.PlanDetails.PriceSection}>
                  <span style={styles.CardList.PricingCard.Plan.PlanDetails.PriceSection.Price}>R$ 899,90</span>
                  <span style={styles.CardList.PricingCard.Plan.PlanDetails.PriceSection.Period}> / mês</span>
                </div>
                <div style={styles.CardList.PricingCard.Plan.PlanDetails.Features}>
                  <span style={styles.CardList.PricingCard.Plan.PlanDetails.Features.Title}>O que vem incluso?</span>
                  <div style={styles.FeatureList}>
                    <div style={styles.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color={CNSColors.brand} />
                      <span style={styles.FeatureList.ListItem.Text}>Toda plataforma CNS Brasil</span>
                    </div>

                    <div style={styles.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color={CNSColors.brand} />
                      <span style={styles.FeatureList.ListItem.Text}>Suporte via Telefone e App</span>
                    </div>

                    <div style={styles.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color={CNSColors.brand} />
                      <span style={styles.FeatureList.ListItem.Text}>Servidor dedicado 8U + 170Gb</span>
                    </div>

                    <div style={styles.FeatureList.ListItem}>
                      <CheckmarkSquare24Filled color={CNSColors.brand} />
                      <span style={styles.FeatureList.ListItem.Text}>Recomendado para até 30 usuários</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style={styles.PlanButton}>
                <span style={styles.PlanButton.Text}>Selecionar Plano</span>
              </div>
            </div>
          </div>

        </div>
  );
};

const styleLarge = {
  PlansBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center"
  },

  Feature: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "64px 128px"
  },

  Text: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "0px 9px",
    justifyContent: "center",
    alignItems: "center",

    Title: {
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "52px",
      textAlign: "center",
      color: CNSColors.brand
    },

    Description: {
      fontSize: "16px",
      lineHeight: "32px",
      textAlign: "center",
      color: "#8EA9C1"
    }
  },

  CardList: {
    display: "flex",
    gap: "32px",
    justifyContent: "center",
    alignItems: "stretch",

    PricingCard: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      padding: "24px",
      alignItems: "center",
      maxWidth: "355px",
      minWidth: "355px",
      backgroundColor: "#ffffff",
      boxShadow: "0px 2px 6px rgba( 20, 20, 43, 0.25)",
      borderRadius: "24px",

      Plan: {
        display: "flex",
        flexDirection: "column",
        gap: "22px",

        PlanTitle: {
          display: "flex",
          flexDirection: "column",
          gap: "18px",

          PlanName: {
            display: "flex",
            gap: "18px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",

            Title: {
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "35px",
              color: CNSColors.brand
            }
          },

          PlanDescription: {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",

            Text: {
              color: "#90A3B4",
              fontSize: "16px",
              lineHeight: "30px",
              textAlign: "center"
            }
          }
        },

        PlanDetails: {
          display: "flex",
          flexDirection: "column",
          gap: "10px",

          PriceSection: {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",

            Price: {
              fontWeight: "bold",
              fontSize: "36px",
              lineHeight: "66px",
              color: CNSColors.brand
            },

            Period: {
              fontSize: "16px",
              lineHeight: "22px",
              color: "#6F6C90"
            }
          },

          Features: {
            display: "flex",
            flexDirection: "column",
            gap: "24px",

            Title: {
              fontWeight: "bold",
              fontSize: "16px",
              lineHieght: "20px",
              color: CNSColors.brand
            }
          }
        }
      }
    }
  },

  FeatureList: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    ListItem: {
      display: "flex",
      flexDirection: "row",
      gap: "14px",
      alignItems: "center",

      Text: {
        fontSize: "16px",
        lineHeight: "20px",
        color: CNSColors.brand
      }
    }
  },

  PlanButton: {
    display: "flex",
    gap: "6px",
    padding: "26px 38px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: CNSColors.brand,
    borderRadius: "96px",

    Text: {
      fontWeight: "bold",
      fontSize: "18px",
      lineHieght: "20px",
      textAlign: "center",
      color: "#ffffff"
    }
  },

  SwitchPlanTypeSection: { 
    display: "flex", 
    flexDirection: "row", 
    gap: "12px", 
    padding: "16px 0px", 
    justifyContent: "center", 
    alignItems: "center",

    Text: {
      fontSize: "16px",
      lineHeight: "30px",
      color: CNSColors.brand
    }
  }
};

const styleSmall = {
  PlansBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center"
  },

  Feature: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "12px",
    justifyContent: "center",
    alignItems: "center"
  },

  Text: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "0px 9px",
    justifyContent: "center",
    alignItems: "center",

    Title: {
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "52px",
      textAlign: "center",
      color: CNSColors.brand
    },

    Description: {
      fontSize: "16px",
      lineHeight: "32px",
      textAlign: "center",
      color: "#8EA9C1"
    }
  },

  CardList: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    justifyContent: "center",
    alignItems: "stretch",

    PricingCard: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      padding: "24px",
      alignItems: "center",
      maxWidth: "355px",
      minWidth: "355px",
      backgroundColor: "#ffffff",
      boxShadow: "0px 2px 6px rgba( 20, 20, 43, 0.25)",
      borderRadius: "24px",

      Plan: {
        display: "flex",
        flexDirection: "column",
        gap: "22px",

        PlanTitle: {
          display: "flex",
          flexDirection: "column",
          gap: "18px",

          PlanName: {
            display: "flex",
            gap: "18px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",

            Title: {
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "35px",
              color: CNSColors.brand
            }
          },

          PlanDescription: {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",

            Text: {
              color: "#90A3B4",
              fontSize: "16px",
              lineHeight: "30px",
              textAlign: "center"
            }
          }
        },

        PlanDetails: {
          display: "flex",
          flexDirection: "column",
          gap: "10px",

          PriceSection: {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",

            Price: {
              fontWeight: "bold",
              fontSize: "36px",
              lineHeight: "66px",
              color: CNSColors.brand
            },

            Period: {
              fontSize: "16px",
              lineHeight: "22px",
              color: "#6F6C90"
            }
          },

          Features: {
            display: "flex",
            flexDirection: "column",
            gap: "24px",

            Title: {
              fontWeight: "bold",
              fontSize: "16px",
              lineHieght: "20px",
              color: CNSColors.brand
            }
          }
        }
      }
    }
  },

  FeatureList: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    ListItem: {
      display: "flex",
      flexDirection: "row",
      gap: "14px",
      alignItems: "center",

      Text: {
        fontSize: "16px",
        lineHeight: "20px",
        color: CNSColors.brand
      }
    }
  },

  PlanButton: {
    display: "flex",
    gap: "6px",
    padding: "26px 38px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: CNSColors.brand,
    borderRadius: "96px",

    Text: {
      fontWeight: "bold",
      fontSize: "18px",
      lineHieght: "20px",
      textAlign: "center",
      color: "#ffffff"
    }
  },

  SwitchPlanTypeSection: { 
    display: "flex", 
    flexDirection: "row", 
    gap: "12px", 
    padding: "16px 0px", 
    justifyContent: "center", 
    alignItems: "center",

    Text: {
      fontSize: "16px",
      lineHeight: "30px",
      color: CNSColors.brand
    }
  }
};

const styles = ScreenHelper().isSmall() 
  ? { ...styleSmall }
  : { ...styleLarge };

const stylesInverted = {
  PricingCard: {
    ...styles.CardList.PricingCard,
    backgroundColor: CNSColors.brand,

    Plan: {
      ...styles.CardList.PricingCard.Plan,

      PlanTitle: {
        ...styles.CardList.PricingCard.Plan.PlanTitle,

        PlanName: {
          ...styles.CardList.PricingCard.Plan.PlanTitle.PlanName,

          Title: {
            ...styles.CardList.PricingCard.Plan.PlanTitle.PlanName.Title,
            color: "#ffffff"
          }
        },

        PlanDescription: {
          ...styles.CardList.PricingCard.Plan.PlanTitle.PlanDescription,

          Text: {
            ...styles.CardList.PricingCard.Plan.PlanTitle.PlanDescription.Text,
            color: "#ffffff",
          }
        }
      },

      PlanDetails: {
        ...styles.CardList.PricingCard.Plan.PlanDetails,

        PriceSection: {
          ...styles.CardList.PricingCard.Plan.PlanDetails.PriceSection,

          Price: {
            ...styles.CardList.PricingCard.Plan.PlanDetails.PriceSection.Price,
            color: "#FFFFFF"
          },

          Period: {
            ...styles.CardList.PricingCard.Plan.PlanDetails.PriceSection.Period,
            color: "#FFFFFF"
          }
        },

        Features: {
          ...styles.CardList.PricingCard.Plan.PlanDetails.Features,

          Title: {
            ...styles.CardList.PricingCard.Plan.PlanDetails.Features.Title,
            color: "#FFFFFF"
          }
        }
      }
    }
  },

  FeatureList: {
    ...styles.FeatureList,

    ListItem: {
      ...styles.FeatureList.ListItem,

      Text: {
        ...styles.FeatureList.ListItem.Text,
        color: "#FFFFFF"
      }
    }
  },

  PlanButton: {
    ...styles.PlanButton,
    backgroundColor: "#FFFFFF",

    Text: {
      ...styles.PlanButton.Text,
      color: CNSColors.brand
    }
  }
};

export default PlansBlock;
