import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Paciente from "./Paciente";
import reportWebVitals from './reportWebVitals';
import { FluentProvider } from "@fluentui/react-components";
import { CNSBrasilTheme } from "./themes/CNSBrasilTheme";
import { BrowserRouter, Navigate, Route, Routes } from "react-router";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <FluentProvider theme={CNSBrasilTheme.light}>
      <BrowserRouter>
        <Routes>
          <Route index element={<App />} />
          <Route path="/paciente" element={<Paciente />} />
          <Route path='/medico' element={<Navigate to="/" />} />
          <Route path='/contato' element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </FluentProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
