import React from "react";

import CNSBrasilLogo from "./CNSLogo.svg";
import GoogleIcon from "./GoogleIcon.svg";
import TwitterIcon from "./TwitterIcon.svg";
import InstagramIcon from "./InstagramIcon.svg";
import LinkedinIcon from "./LinkedinIcon.svg";
import { useNavigate } from "react-router";

const styles = {
  FooterBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "64px 128px",
    backgroundColor: "#2864AE",
    flex: 1
  },

  Feature: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    justifyContent: "start",
    alignItems: "stretch",
  },

  Content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    gap: "16px"
  },

  BrandColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "24px",
    flex: 1
  },

  BrandLogo: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "0px 12px",
    alignItems: "center",

    Logo: {
      width: "48px",
      height: "48px"
    },

    BrandName: {
      fontSize: "24px",
      lineHeight: "18px",
      color: "#ffffff"
    }
  },

  Slogan: {
    fontSize: "16px",
    lineHeight: "28px",
    color: "#E3F0FF"
  },

  SocialLinks: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
    color: '#E3F0FF',

    Link: {
      display: "flex",
      width: "56px",
      height: "56px",
      borderRadius: "28px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba( 227, 240, 255, 0.25)",
      color: '#E3F0FF'
    }
  },

  Column: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    flex: 1,
    minHeight: "260px",

    Title: {
      fontWeight: "bold",
      fontSize: "18px",
      color: "#ffffff"
    },

    FeatureLink: {
      fontSize: "16px",
      color: "#E3F0FF"
    }
  },

  Copyright: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    justifyContent: "center",
    alignItems:  "center",
    padding: "16px 0px",

    Text: {
      fontSize: "14px",
      color: "#E3F0FF",
      textAlign: "center"
    }
  }
};

const FooterBlock = () => {
  const nav = useNavigate();
  
  return (
    <div style={styles.FooterBlock}>
      <div style={styles.Feature}>
        <div style={styles.Content}>
          <div style={styles.BrandColumn}>
            <div style={styles.BrandLogo}>
              <img src={CNSBrasilLogo} style={styles.BrandLogo.Logo} alt="CNS Brasil logo" />
              <span style={styles.BrandLogo.BrandName}>CNS Brasil</span>
            </div>
            <span style={styles.Slogan}>Conectando o mundo da saúde.</span>
            <div style={styles.SocialLinks}>
              <div style={styles.SocialLinks.Link}>
                <img src={GoogleIcon} width={18} height={18} alt="Google icone" />
              </div>
              <div style={styles.SocialLinks.Link}>
                <img src={TwitterIcon} width={18} height={18} alt="Twitter icone" />
              </div>
              <div style={styles.SocialLinks.Link}>
                <img src={InstagramIcon} width={18} height={18} alt="Instagram icone" />
              </div>
              <div style={styles.SocialLinks.Link}>
                <img src={LinkedinIcon} width={18} height={18} alt="Linkedin icone" />
              </div>
            </div>
          </div>
          <div className="Spacer"></div>

          <div style={styles.Column}>
            <span style={styles.Column.Title}>Soluções</span>
            <span onClick={() => nav("/paciente")} style={styles.Column.FeatureLink}>CNS Paciente</span>
            <span style={styles.Column.FeatureLink}>CNS Agenda</span>
            <span style={styles.Column.FeatureLink}>CNS Telemed</span>
          </div>

          <div style={styles.Column}>
            <span style={styles.Column.Title}>Serviços</span>
            <span style={styles.Column.FeatureLink}>Mapa da Saúde</span>
            <span style={styles.Column.FeatureLink}>Integração de dados</span>
          </div>

          <div style={styles.Column}>
            <span style={styles.Column.Title}>Empresas</span>
            <span style={styles.Column.FeatureLink}>Sobre a CNS</span>
            <span style={styles.Column.FeatureLink}>Política de Privacidade</span>
            <span style={styles.Column.FeatureLink}>Contato</span>
          </div>
        </div>
        <div style={styles.Copyright}>
          <span style={styles.Copyright.Text}>Copyright © 2024.  Centro Nacional de Saúde LTDA - CNPJ: 53.498.628/0001-25</span>
        </div>
      </div>
    </div>
  );
};

export default FooterBlock;
